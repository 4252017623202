import axios from "axios";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import { useParams, useLocation, useNavigate } from 'react-router-dom'; // Add useParams here

import "rsuite/dist/rsuite.min.css";
import { GetCouponById, UpdateRedeemedCoupon, UploadProduct, GetUploadedProductCount, UploadWooCommerce, updateUserDetails, getUserById } from "../../../config/api";
import Styles from "../Product-Data/Product.module.css";
//import Styles2 from '../../Tables/table.module.css';
import Styles4 from '../../components/Tables/table.module.css';

import {
  getWishlistCategoriesById,
  updateWishlistCategory,
  removeWishlistCategory,
  getMainCategories,
  uploadFile,
} from '../../../config/api';
import {
  TextField,
  Button,
  Typography,
  Box,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';


const RedeemCoupon = ({ isAdmin, activeTab, activeTabHandle, langData, langConfig }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [wishlistDetails, setWishlistDetails] = useState({
    id: '',
    categoryName: '',
    englishName: '',
    categoryDescription: '',
    categoryIcon: '',
    categoryImage: '',
    mainCategory: [],
  });
  const [mainCategories, setMainCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [icon, setIcon] = useState('');
  const [image, setImage] = useState('');
  const [couponDetails, setCouponDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [order, setOrder] = useState();
  const [productName, setProductName] = useState('')
  const [experienceOptionName, setExperienceOptionname] = useState('')
  const [coupon, setCoupon] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const fetchCoupon = async () => {
    try {
      const response = await axios.get(GetCouponById(), {
        params: { couponId: productLink }, // Send coupon code as a query parameter
      });

  
      if (response.data?.result) {
        // Update state with coupon and order details
        setCoupon(response.data.data.coupon); // Accessing the coupon object correctly
        setOrder(response.data.data.order) // Accessing the order object correctly
        setProductName(response.data.data.productDisplayName);
        setExperienceOptionname(response.data.data.experienceOptionName);
        setShowDetails(true)
      } else {
        alert('Failed to fetch coupon details. Please check the coupon code and try again.');
      }
  
  
    
    } catch (error) {
      console.error('Error fetching coupon details:', error);
      alert('Failed to fetch coupon details. Please check the coupon code and try again.');
    }
  };
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWishlistDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
	  
    }));
  };

  const handleSave = async () => {
   
    try {
      const mainCategoryArray = mainCategories.map((category) => ({
        id: category.id,
        checked: selectedCategories.includes(category.name),
        name: category.name,
      }));

      const response = await axios.post(updateWishlistCategory(), {
        id: id,
        categoryName: wishlistDetails.categoryName,
        englishName: wishlistDetails.englishName,
        description: wishlistDetails.categoryDescription,
        imageLink: image,
        iconLink: icon,
        mainCategory: JSON.stringify(mainCategoryArray),
      });

      if (response.data.result) {
        //alert('Success');
        navigate('/product-categories-management');
      } else {
        alert('Error updating wishlist category');
      }
    } catch (error) {
      console.error('Error updating wishlist category:', error);
      alert(`Error updating wishlist category: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.post(removeWishlistCategory(id));

      if (response.data.result) {
        alert('אירוע נמחק');
        navigate('/');
      } else {
        alert('Error removing user');
      }
    } catch (error) {
      console.error('Error removing user:', error);
      alert(`Error removing user: ${error.message}`);
    }
  };

  const handleCheckOption = (event) => {
    setSelectedCategories(event.target.value);
  };

  const handleGetMainCategories = async () => {
    try {
      const response = await axios.get(getMainCategories());

      const { data } = response;
      let mainCatNames = null;
      if (data.result) {
        mainCatNames = data.mainCategoriesList.map((category) => ({
          id: category.id,
          name: category.categoryName,
          checked: false,
        }));
        setMainCategories(mainCatNames);
      }
      return { mainCatNames };
    } catch (error) {
      console.log(error);
      return { mainCatNames: null };
    }
  };

  useEffect(() => {
    handleGetMainCategories();
  }, []);

  function returnActiveCat() {
    return Array.isArray(selectedCategories)
      ? selectedCategories.join(', ')
      : '';
  }

  const companyId = id;
  const [productLink, setProductLink] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [fileError, setFileError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isWoocommerce, setIsWoocommerce] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [sessionStartTime, setSessionStartTime] = useState('');



  const updateRedeemedCoupon = async () => {
    try {
      // Debugging: Log coupon data
      console.log("Coupon Data:", coupon);
  
      // Check if coupon ID is available
      if (!coupon || !coupon.id) {
        setErrorMessage("Invalid coupon. Please check and try again.");
        return;
      }
  
      // Define the payload
      const payload = {
        couponId: coupon.id, // Ensure coupon.id is valid and correct
      };
  
      // Correct API URL with protocol
      const response = await axios.post(
        `https://server.yyyshopping.com/api/ProductCategory/UpdateRedeemedCoupon`, // Make sure the URL is correct
        payload,
        {
          headers: {
            "Content-Type": "application/json", // Ensure content type is set
          },
        }
      );
  
      // Handle response
      if (response.data.result) {
        alert("עודכן בהצלחה"); // Success message in Hebrew
      } else {
        // Show the error message from the backend
        alert(response.data.message || "Failed to update the coupon.");
      }
    } catch (error) {
      console.error("Error updating the coupon:", error);
      alert("An error occurred while updating the coupon. Please try again.");
    }
  };
  




  return (
    <>
    <div className={Styles.containerUser}>
    <div className="row">
    <div className="col-md-6">
  <div>
    <div className={Styles4.uploadContainer}>
      <input
        className={`${Styles4.fileInput} form-control my-3`}
        type="text"
        value={productLink}
        onChange={(e) => setProductLink(e.target.value)}
        placeholder="הכנס קוד קופון"
      />
 <button
  onClick={fetchCoupon}
  style={{
    padding: '0px 15px',
    backgroundColor: '#fcfcfc',
    color: '#154360',
    border: '1px solid #154360',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '10px 0px',
    marginRight: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '44px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  }}
>
  אישור
</button>


 {showDetails &&

<button
  onClick={updateRedeemedCoupon}
  style={{
    padding: '0px 15px',
    backgroundColor: '#fcfcfc',
    color: '#154360',
    border: '1px solid #154360',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '10px 0px',
    marginRight: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '44px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  }}
>
  אשר מימוש קופון
</button>}

    </div>
  </div>
</div>

    </div>
  </div>

  {showDetails &&

<div className="card mt-5">

<div className="card-body">
  <div className={Styles4.tableResponsive}>
    <table className={`${Styles4.table} ${Styles4.tableStriped}`}>
      <thead>
        <tr>
          <th  className={Styles4.horizantal}>
            פירוט הקופון
          </th>
          <th>
            תאריך רכישה
          </th>
          <th>
            מי רכש
          </th>
          <th>
            מי המקבל
          </th>
    
       
        </tr>
      </thead>
        <tbody>
    

   
          <tr>
               <td>{productName} {experienceOptionName}</td>
               <td>
                {new Date(order.creationDate).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </td>
               <td>{order?.buyerFullName}</td>
               <td>{order?.ownerFullName}</td>
          </tr>
    

               

        </tbody>
    
    </table>
  </div>
</div>
</div>
              
  
  }


</>
);
};

export default RedeemCoupon;
