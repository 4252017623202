import axios from "axios";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { UploadProduct, GetUploadedProductCount, UploadWooCommerce, updateUserDetails, getUserById } from "../../../config/api";
import Styles from "./Product.module.css";
import { useParams } from 'react-router-dom';

const Product = ({ langData, langConfig, providerName, id}) => {
  const companyId = id;
  const [productLink, setProductLink] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [fileError, setFileError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isWoocommerce, setIsWoocommerce] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [sessionStartTime, setSessionStartTime] = useState('');

useEffect(() => {
  const fetchUserData = async () => {
      try {
          const response = await axios.get(getUserById(id));
          const user = response.data;
          //      alert(user)
          console.log("*******", user)
          debugger;
          setProductLink(user.importUrl)

      


    
      } catch (error) {
          console.error(error);
      }
  };

  fetchUserData();
}, [id]);


  useEffect(() => {
    if (productLink?.includes("wp-json/wc/v3/products")) {
      setIsWoocommerce(true);
    } else {
      setIsWoocommerce(false);
    }
  }, [productLink]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchProductCount();
    }, 2000); // Fetch product count every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [sessionStartTime]);

  const fetchProductCount = async () => {
    try {
      const data = {
        companyId: companyId,
        since: sessionStartTime
      };

      console.log("Data sent to API:", JSON.stringify(data)); // Log the data being sent

      const response = await axios.post(GetUploadedProductCount(), data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setProductCount(response.data);
    } catch (error) {
      console.error("Error fetching product count:", error.response ? error.response.data : error.message);
    }
  };

  const uploadFile = async () => {
    setDataLoading(true);
    setSuccessMessage("");
    setFileError("");



    try {
      const response = await axios.post(updateUserDetails(), {
        userId: id,
        importUrl: productLink
      });
    
      if (response.data.result) {
        // Add your success handling code here
      } else {
        alert('!!!!!!!!!!!!!!!!!!');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      alert(`Error updating user data: ${error.message}`);
    }
    









    const currentDate = new Date();
    const offset = currentDate.getTimezoneOffset() * 60000; // Get the timezone offset in milliseconds
    const localISOTime = new Date(currentDate.getTime() - offset).toISOString().slice(0, -1); // Adjust the date by the offset and convert to ISO string
    setSessionStartTime(localISOTime); // Set the session start time when upload button is clicked

    const data = isWoocommerce
      ? { providerId: companyId, link: productLink, userName, password }
      : { companyId: companyId, productLink: productLink };

    const uploadApi = isWoocommerce ? UploadWooCommerce() : UploadProduct();

    try {
      await axios.post(uploadApi, data);
      setFileError("");
      setDataLoading(false);
      setSuccessMessage("כל המוצרים עלו");
      fetchProductCount(); // Update count immediately after upload
    } catch (error) {
      console.error("Error uploading file:", error.response ? error.response.data : error.message);
      setFileError("Error uploading file. Please try again.");
      setDataLoading(false);
    }
  };

  return (
    <div className={Styles.containerUser}>
      <div className="row">
        <div className="col-md-6">
          <div>
        
            <div className={Styles.uploadContainer}>
              <input
                className={`${Styles.fileInput} form-control my-3`}
                type="text"
                value={productLink}
                onChange={(e) => setProductLink(e.target.value)}
                placeholder="קישור ל-API של המוצרים"
              
              />
              {fileError && (
                <p className={`${Styles.error} my-3`}>{fileError}</p>
              )}
              {isWoocommerce && (
                <>
                  <input
                    className={`${Styles.fileInput} form-control my-3`}
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="שם משתמש"
                  />
                  <input
                    className={`${Styles.fileInput} form-control my-3`}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="סיסמא"
                  />
                </>
              )}
              <button
                onClick={uploadFile}
                className={`${Styles.btnStyle} my-3`}
              >
                {langData[langConfig.userType][langConfig.lang].tab3.enableBtn}
              </button>
              {successMessage && (
                <p className={`${Styles.success} my-3`}>{successMessage}</p>
              )}
              {dataLoading && (
                <div className="text-center" style={{ height: "200px" }}>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>
              )}
              <div className={`${Styles.success} my-3`}> 
              <p>{`מספר מוצרים שעלו: ${productCount}`}</p>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
