import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { DOMAIN } from '../../../constants/envUrl';
import EditIcon from '@mui/icons-material/Edit';
import Styles2 from '../../components/Header/Header.module.css';
import AddExperience from "./AddExperience";
import RedeemCoupon from "./RedeemCoupon";

import Header from './Header'

import InfiniteScroll from 'react-infinite-scroll-component';
import {  Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import Styles from '../../components/Tables/table.module.css';
//import Styles2 from '../../components/Tables/table-products.module.css';
import { getProductsNames, getAllExperiences } from '../../../config/api'; // Ensure correct import

import {
  getWishlistCategoriesById,
  updateWishlistCategory,
  removeWishlistCategory,
  getMainCategories,
  uploadFile,
} from '../../../config/api';
import {
  TextField,
  Button,
  Typography,
  Box,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';
import { useLocation } from 'react-router-dom';


const Experiences = ({ isAdmin, activeTabExperience, setActiveTabExperience, langData, langConfig,
  userId,
  data,
  tableLoading,
  heading,
  hasMore,
  fetchMoreData,
  onCodeFilterChange,
  onProviderSelectChange,
  onCategoryIdFilterChange,
  productNameOptions,
  productName,
  onProductNameSelectChange,
  providerOptions,
  selectedProvider,
  categoriesList,
  selectedCategory,
  removeProduct
 }) => {
  const location = useLocation();
  const { id } = useParams();
  const [wishlistDetails, setWishlistDetails] = useState({
    id: '',
    categoryName: '',
    englishName: '',
    categoryDescription: '',
    categoryIcon: '',
    categoryImage: '',
    mainCategory: [],
  });
 // const [mainCategories, setMainCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [icon, setIcon] = useState('');
  const [image, setImage] = useState('');
  const [products, setProducts] = useState([]);
 const [activeTab, setActiveTab] = useState(0)

  // Fetch products with applied filters
  const fetchExperienceProducts = async (page = 1, reset = false) => {


    try {
      const response = await axios.post(getAllExperiences(), {

        pageNumber: 1,
        pageSize: 30,
        categoryId: '',
        code:'',
        productName:'',
        providerId: isAdmin? "": userId,

        // Use categoryId.value if categoryId is an object
      });
      const { products, totalItems } = response.data;
      if (reset) {
        setProducts(products);
      } else {
        setProducts(prevProducts => [...prevProducts, ...products]);
      }
   //   setTotalItems(totalItems);
    //  setHasMore(page * itemsPerPage < totalItems);
      console.log('Products fetched:', products);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  //  setDataLoading(false);
  };


  useEffect(() => {
    if (activeTab === 0) {
      fetchExperienceProducts();
    }
  }, [activeTab]); // תלות ב-activeTab
  












  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWishlistDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
	  
    }));
  };


 

  function returnActiveCat() {
    return Array.isArray(selectedCategories)
      ? selectedCategories.join(', ')
      : '';
  }

  const uploadImage = async (event, type) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(uploadFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
       // debugger

        const data = response.data;
        if (response.status !== 200) return;
        if (type === 'icon') setIcon(`/images/${data}`);
        if (type === 'image') setImage(`/images/${data}`);
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
      }
    } else {
      console.error('No file selected');
    }
  };
  const navigate = useNavigate();

 {/* const editProduct = (product) => {
    navigate(`${product.id}`, { state: { product, mainCategories } });
  };*/}


  const loadProductOptions = async (inputValue, callback) => {
    console.log(`Input value: ${inputValue}`);

    if (inputValue?.length < 2) {
      console.log("Input length is less than 2, returning empty array");
      callback([]);
      return;
    }

    try {
      console.log(`Fetching product names for input: ${inputValue}`);
      const response = await axios.post(getProductsNames(), { word: inputValue, pageSize: 10, pageNumber: 1, providerId: userId });
      console.log("Response data:", response.data);

      if (response.data.productNames) {
        const options = response.data.productNames.map(name => ({
          value: name,
          label: name,
        }));
        console.log("Mapped options:", options);
        callback(options);
      } else {
        console.warn("No product names found in the response.");
        callback([]);
      }
    } catch (error) {
      console.error('Error fetching product name options:', error);
      callback([]);
    }
  };
const activeTabHandle = (id) => {
    setActiveTab(id)
  }

{/*
  const loadProviderOptions = useCallback(
    debounce((inputValue, callback) => {
      const filteredOptions = providerOptions.filter(option =>
        option.label && option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 300),
    [providerOptions]
  );*/}

 {/* const loadCategoryOptions = useCallback(
    debounce((inputValue, callback) => {
      const filteredOptions = categoriesList.filter(option =>
        option.label && option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 300),
    [categoriesList]
  );*/}

  return (
    <>



<Header
        isAdmin={isAdmin}
        activeTabHandle={activeTabHandle}
        activeTab={activeTab}
        langData={langData}
        langConfig={langConfig}
      />

      {activeTab === 1&& 
       <AddExperience
    isAdmin={isAdmin}
    activeTabHandle = {activeTabHandle}
    langData={langData}
    langConfig={langConfig}
    userId={userId}
  />}
      {activeTab === 2 && <RedeemCoupon
    isAdmin={isAdmin}
    langData={langData}
    langConfig={langConfig}
    activeTab={2}
  />}
  

    
    {activeTab == 0 &&
   

<div className="card mt-5">

<div className="card-header">
  <h2 className="card-title">
    מוצרים
  </h2>
</div>
<div className="card-body">
  <div className={Styles.tableResponsive}>
    <table className={`${Styles.table} ${Styles.tableStriped}`}>
                <thead>
                  <tr>
                  {/*  <th>קוד</th>*/}
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.prodName}</th>
                    <th>תמונה</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.price}</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.discountPrice}</th>
                    <th>{langData[langConfig.userType][langConfig.lang].tab5.table.description}</th>
                    <th>תאריך הכנסה</th>
              {/*      {isAdmin && <th>{langData[langConfig.userType][langConfig.lang].tab5.table.providerId}</th>}
      {/*  <th>קטגוריה</th>*/}
                    <th></th>
                  </tr>
                </thead>
                {!tableLoading ? (
                  <tbody>
                    {products?.length > 0 ? (
                      products?.map((item, index) => (
                        <tr key={index} style={{ verticalAlign: 'top' }}>
                       {/*   <td>{item.productCode}</td>*/}
                          <td>{item.name}</td>
                          <td className={Styles2.imagesContainer}>
                            <div className={Styles2.imagesHolder}>
                              <div className={Styles2.imageWrapper}>
                              <img
   style={{width: '100%'}}                           
  className={Styles2.productImage}
  src={
    !item.manuallyAdd
      ? item.images
      : `https://server.yyyshopping.com${item.images.replace(",", "")}`
  }
  alt=""
/>



                              </div>
                            </div>
                          </td>
                          <td>
                            {item.price?
                            <> <span>₪</span>{item.price}</>
                            :""
                            }
                            </td>
                            <td>
                            {item.discountPrice?
                            <> <span>₪</span>{item.discountPrice}</>
                            :""
                            }
                            </td>
                           
                          <td dangerouslySetInnerHTML={{ __html: item.description }}></td>
                          <td>
                            {item?.creationDate && (() => {
                              const date = new Date(item.creationDate);
                              const day = ("0" + date.getDate()).slice(-2);
                              const month = ("0" + (date.getMonth() + 1)).slice(-2);
                              const year = date.getFullYear();
                              return `${day}/${month}/${year}`;
                            })()}
                          </td>
                          <td></td>
                     
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={isAdmin ? 10 : 9} className="text-center py-5">No data</td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={isAdmin ? 10 : 9} className="text-center" style={{ height: "200px" }}>
                        <div className="spinner-border text-primary" role="status"></div>
                      </td>
                    </tr>
                  </tbody>
                )}
 </table></div></div></div>
              
              
              }
             </>
  );
};

export default Experiences;
