import Reactб, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "../../components/Header/Header.module.css";

const Header = ({ isAdmin, activeTab, activeTabHandle, langData, langConfig }) => {
  // - Mapping categories (это то что Вы делаете сейчас)
  // - Manage Product Categories (это то что я сейчас послал)
  // - Manage Main Categories (это я Вам пришлю попозже)

  return (
    <div className={Styles.containerUser1}>
      <div>
        <div className="d-flex align-items-center flex-wrap" style={{position:"relative", right:'46px'}}>
        <button
            className={`${Styles.buttonStyle} ${activeTab === 0 && Styles.buttonActive
              }`}
            onClick={() => {
              activeTabHandle(0)
            }}
          >
            מוצרים
          </button>

          <button
            className={`${Styles.buttonStyle} ${activeTab === 1 && Styles.buttonActive
              }`}
            onClick={() => {
              activeTabHandle(1)
            }}
          >
            הוספת מוצר שירות
        </button>

        
        <button
            className={`${Styles.buttonStyle} ${activeTab === 2 && Styles.buttonActive
              }`}
            onClick={() => {
              activeTabHandle(2)

            }}
          >
מימוש קופון          </button>
       
        </div>
      </div>
    </div>
  );
};

export default Header;
