import { API_HUB_URL } from "../constants/envUrl";
// API_HUB_URL = 'https://localhost:7223/api'
export const getUser = () => `${API_HUB_URL}/Authenticate/GetUsers`;
// export const getUser = () => `${API_HUB_URL}/*  *//Authenticate/GetUsers`;


export const getUserData = (id) =>
  `${API_HUB_URL}/Authenticate/getUserData?userId=${id}`;

export const getUserById = (id) =>
  `${API_HUB_URL}/Authenticate/getUserById?userId=${id}`;

export const removeUserById = (id) =>
  `${API_HUB_URL}/Authenticate/removeUserById?userId=${id}`;

  export const removeProviderById = (id) =>
  `${API_HUB_URL}/Authenticate/removeProviderById?providerId=${id}`;



export const getOrderById = (id) =>
  `${API_HUB_URL}/ProductCategory/getOrderById?orderId=${id}`;

export const GetCouponById = (id) =>
  `${API_HUB_URL}/ProductCategory/GetCouponById`;

export const enableDisableSingleUser = (id, IsEnable) =>
  `${API_HUB_URL}/Authenticate/UpdateUserStatus?userId=${id}&isEnabled=${IsEnable}`;

export const updateUserDetails = () =>
  `${API_HUB_URL}/Authenticate/UpdateUserDetails`;

  export const createSeller = () =>
  `${API_HUB_URL}/ProductCategory/createSeller`;



export const UploadProduct = () =>
  `${API_HUB_URL}/Product/SaveProductByJsonFile`;

export const GetUploadedProductCount = () => {
  return `${API_HUB_URL}/Product/GetUploadedProductCount`;
};
export const UploadWooCommerce= () =>
  `${API_HUB_URL}/ProductCategory/SaveProductsFromWooCommerce`;
export const getProductsByFilters= () =>
  `${API_HUB_URL}/ProductCategory/getAllProducts`;


export const getAllExperiences= () =>
  `${API_HUB_URL}/ProductCategory/getAllExperiences`;

export const getProductsNames= () =>
  `${API_HUB_URL}/ProductCategory/getProductsNames`;

export const getProvidersNames= () =>
  `${API_HUB_URL}/ProductCategory/getProvidersNames`;



export const getOderList = (datetime) =>
  `${API_HUB_URL}/Order/getOrderList?datetime=${datetime}`;

export const SaveOrder = () =>
  `${API_HUB_URL}/order/SaveOrder`;

export const getProviderCategoriesList = (id) =>
  `${API_HUB_URL}/ProductCategory/GetMapProviderCategoryList?ProviderId=${id}`;

  export const getMainCategoryById = (mainCategoryId) =>
  `${API_HUB_URL}/ProductCategory/GetMainCategoryById?mainCategoryId=${mainCategoryId}`;



  export const GetAllCategoriesByProductCatId = (id) =>
  `${API_HUB_URL}/ProductCategory/GetAllCategoriesByProductCatId?id=${id}`;


  export const getWishlistCategoriesById = (id) =>
  `${API_HUB_URL}/ProductCategory/GetWishlistCategoriesById?id=${id}`;

export const getProviderCategoriesListOptions = () =>
  `${API_HUB_URL}/ProductCategory/getProductCategoryList`;

export const UpdateProviderCategoriesListOptions = () =>
  `${API_HUB_URL}/ProductCategory/MapProviderCategory`;
  
  export const generateTransfer = () =>
  `${API_HUB_URL}/ProductCategory/GenerateTransfer`;

export const getOTP = (phoneNumber) =>
  `${API_HUB_URL}/Authenticate/GetOTP?phoneNumber=${phoneNumber}`;

export const verifyOTPEndpoint = () =>
  `${API_HUB_URL}/Authenticate/LoginWithOTP`;


export const getProviderOrders = (providerId) =>
  `${API_HUB_URL}/ProductCategory/GetProviderOrders?providerId=${providerId}`;

export const getAllOrders = () =>
  `${API_HUB_URL}/ProductCategory/getAllOrders`;


export const getAbandonedCart = () =>
  `${API_HUB_URL}/ProductCategory/getAbandonedCart`;

  export const getProvidersReport = () =>
  `${API_HUB_URL}/ProductCategory/getProvidersReport`;


  export const   getAllCompleteOrders  = () =>
  `${API_HUB_URL}/ProductCategory/GetCompleteOrders`;


  export const getAllCompleteProvidersOrders = () => 
    `${API_HUB_URL}/ProductCategory/getCompleteProviderOrders`;

export const updateOrderStatus = () =>
  `${API_HUB_URL}/ProductCategory/UpdateOrderStatus`;

export const UpdateRedeemedCoupon = () =>
  `${API_HUB_URL}/ProductCategory/UpdateRedeemedCoupon`;

export const updateOrder = () =>
  `${API_HUB_URL}/ProductCategory/UpdateOrder`;



export const getProviderOrdersById = (orderId) =>
  `${API_HUB_URL}/ProductCategory/GetOrderById?orderId=${orderId}`;


export const removeOrderById = (orderId) =>
  `${API_HUB_URL}/ProductCategory/RemoveOrderById=${orderId}`;

export const getProductCategory = () =>
  `${API_HUB_URL}/ProductCategory/getProductCategory`;


export const getAllCategories = () =>
  `${API_HUB_URL}/ProductCategory/getAllCategories`;


export const addMainCategoriesToProductCategory = () =>
  `${API_HUB_URL}/ProductCategory/addMainCategoriesToProductCategory`;

export const gellAllCategoriesByProductCat = () =>
  `${API_HUB_URL}/ProductCategory/gellAllCategoriesByProductCat`;


export const updateProductCategory = () =>
  `${API_HUB_URL}/ProductCategory/updateProductCategory`;

  export const updateViewOrder = () =>
  `${API_HUB_URL}/ProductCategory/updateViewOrder`;

export const uploadFile = () =>
  `${API_HUB_URL}/ProductCategory/upload`;


export const createProductCategory = () =>
  `${API_HUB_URL}/ProductCategory/createProductCategory`;


export const getMainCategories = () =>
  `${API_HUB_URL}/ProductCategory/getMainCategories`;


export const updateMainCategory = () =>
  `${API_HUB_URL}/ProductCategory/updateMainCategory`;



export const createMainCategory = () =>
  `${API_HUB_URL}/ProductCategory/createMainCategory`;


export const getWishlistCategories = () =>
  `${API_HUB_URL}/ProductCategory/getWishlistCategories`;


export const createWishlistCategory = () =>
  `${API_HUB_URL}/ProductCategory/createWishlistCategory`;

export const updateWishlistCategory = () =>
  `${API_HUB_URL}/ProductCategory/updateWishlistCategory`;


export const getUserProductsByCatId = (userId, catId) =>
  `${API_HUB_URL}/ProductCategory/getUserProductsByCatId?userId=${userId}&catId=${catId}`;







export const UpdateCreateProduct = () =>
  `${API_HUB_URL}/ProductCategory/UpdateCreateProduct`;


export const UpdateCreateExperience = () =>
  `${API_HUB_URL}/ProductCategory/UpdateCreateExperience`;

export const getProductsByProviderAndCategory = () =>
  `${API_HUB_URL}/ProductCategory/GetProductsByProviderAndCategory`;


export const addNewProvider = () =>
  `${API_HUB_URL}/Authenticate/register`;



export const removeProductById = () =>
  `${API_HUB_URL}/ProductCategory/removeProductById`;




  export const removeWishlistCategory = (id) =>
  `${API_HUB_URL}/ProductCategory/RemoveWishlistCategory?id=${id}`;

  export const removeMainCategory = (id) =>
  `${API_HUB_URL}/ProductCategory/RemoveMainCategory?id=${id}`;

  export const removeProductCategory = (id) =>
  `${API_HUB_URL}/ProductCategory/RemoveProductCategory?id=${id}`;