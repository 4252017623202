import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./Header.module.css";

const Header = ({ isAdmin, userId, langConfig, handleLangChange, langData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
    <div className={Styles.containerUser} >      <button
  style={{ position: 'relative', right: '1140px', top: '15px', zIndex: 3 }}
  className="btn btn-secondary"
            onClick={() => {
              localStorage.removeItem('UserId');
              window.location.reload();
            }}
          >
            {langData[langConfig.userType][langConfig.lang].logout}
          </button>
      <div >

        {/* <div className={Styles.headingUser}> */}
        <h1 className={Styles.headingUser}>
          {langData[langConfig.userType][langConfig.lang].title}</h1>
        {/* </div> */}
        <div className="heading_manu d-flex align-items-center flex-wrap " style={{position: 'relative',
bottom:'8px',
right: '46px'}}>
          {
            isAdmin && (
              <button
                className={`${Styles.buttonStyle} ${location.pathname === "/" && Styles.buttonActive
                  }`}
                onClick={() => {
                  navigate("/");
                }}
              >
                {langData[langConfig.userType][langConfig.lang].users.tabName}
              </button>
            )
          }
          {isAdmin &&
          <button
            className={`${Styles.buttonStyle} ${location.pathname === "/provider" && Styles.buttonActive
              }`}
            onClick={() => {
              navigate("/provider");
            }}
          >
            {langData[langConfig.userType][langConfig.lang].tab1.tabName}       
          </button>}

     
  
  
          {false && (
            <button
              className={`${Styles.buttonStyle} ${location.pathname === `/categories/${userId}` && Styles.buttonActive
                }`}
              onClick={() => {
                navigate(`/categories/${userId}`);
              }}
            >
              {langData[langConfig.userType][langConfig.lang].categories.tabName}
            </button>
          )}
          {false && (
            <button
              className={`${Styles.buttonStyle} ${location.pathname === `/import-products/${userId}` && Styles.buttonActive
                }`}
              onClick={() => {
                navigate(`/import-products/${userId}`);
              }}
            >
              {langData[langConfig.userType][langConfig.lang].tab3.tabName}
            </button>
          )}
          {isAdmin && (
            <button
              className={`${Styles.buttonStyle} ${location.pathname === "/product-categories-management" && Styles.buttonActive
                }`}
              onClick={() => {
                navigate("/product-categories-management");
              }}
            >
קטגוריות            </button>
          )}
          <button
            className={`${Styles.buttonStyle} ${location.pathname === "/orders" && Styles.buttonActive
              }`}
            onClick={() => {
              navigate("/orders");
            }}
          >
            {langData[langConfig.userType][langConfig.lang].tab4.tabName}
          </button>
          {!isAdmin && (
            <button
              className={`${Styles.buttonStyle} ${location.pathname === `/provider-products` && Styles.buttonActive
                }`}
              onClick={() => {
                navigate(`/provider-products`);
              }}
            >
              {langData[langConfig.userType][langConfig.lang].tab5.tabName}
            </button>
          )}
             {isAdmin &&
          <button
            className={`${Styles.buttonStyle} ${location.pathname === "/abandoned-cart" && Styles.buttonActive
              }`}
            onClick={() => {
              navigate("/abandoned-cart");
            }}
          >
            עגלות נטושות
          </button>
          }
          {isAdmin &&
          <button
            className={`${Styles.buttonStyle} ${location.pathname === "/all-products-management" && Styles.buttonActive
              }`}
            onClick={() => {
              navigate("/all-products-management");
            }}
          >
           מוצרים
          </button>
          }
       
    

               <button
            className={`${Styles.buttonStyle} ${location.pathname === "/experiences" && Styles.buttonActive
              }`}
            onClick={() => {
              navigate("/experiences");
            }}
          >
           מוצרי שירות
          </button>
          
        

<button
            className={`${Styles.buttonStyle} ${location.pathname === "/reports" && Styles.buttonActive
              }`}
            onClick={() => {
              navigate("/reports");
            }}
          >
           דוחות
          </button>
         {/*<div className="toggle-translate">
            <button style={{marginLeft: '1px'}}className={`btn me-1 ${langConfig.lang === 'eng' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => handleLangChange("hebrew")}>eng</button>
            <button  className={`btn ${langConfig.lang === 'hebrew' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => handleLangChange("hebrew")}>hebrew</button>
          </div>*/}
       
          {/* <button
            className={`${Styles.buttonStyle} ${
              location.pathname === "/categories" && Styles.buttonActive
            }`}
            onClick={() => {
              navigate("/categories");
            }}
          >
            Categories
          </button> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
